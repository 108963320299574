export const CATEGORIES = {
    BODY_AND_FOOD: "BODY&FOOD",
    FAMILY: "FAMILY",
    TRANSPORTS: "TRANSPORTS",
    ANIMALS: "ANIMALS",
    SEASONS: "SEASONS"
};
export const REPRESENTATION = {
    ICONIC: "ICONIC",
    MIXED: "MIXED",
    SYMBOLIC: "SYMBOLIC"
};